(() => {
  const transitionDuration = 300,
    vwSize = {
      width: $(window).width(),
      height: $(window).height()
    };

  // ------------------------- INIT ROW & SIZE
  function goIn() {
    TweenMax.to($('.choix-langue'), 0.5, {
      opacity: 1
    });
    $('.langue').on('click', function () {
      TweenMax.to($('.choix-langue'), 0.5, {
        opacity: 0
      });
      TweenMax.delayedCall(.2, goToHome, [this.getAttribute('id')]);
    });
  }

  function goToHome(langue) {
    window.location.href = "/" + langue
  }
  var myVar;

  function initPreload() {
    TweenMax.to($('.preloader > .charg'), 0.5, {
      opacity: 1
    });
    TweenMax.to($('.mavideo'), 1, {delay:.4,
      opacity: 1
    });
    myVar = setTimeout(goSite, 7000);
  }

  function goSite() {
    clearTimeout(myVar);
    TweenMax.to($('.preloader'), 0.5, {
      opacity: 0,
      display: "none"
    });
  }

  function initRow() {
    let list = $('.section-row');

    list.width(list.length * vwSize.width)
      .height(vwSize.height)
      .fadeOut(0);
    $('.en-image').height($(window).height());
    $('.in-picture').height($(window).height());
  }

  function initArticle() {
    let list = $('.section-row > article');

    list.width(vwSize.width)
      .height(vwSize.height);

    var rand = Math.floor((Math.random() * 3) + 1);
    $('#accueil').addClass('fond' + rand);
    $('#home').addClass('fond' + rand);
    console.log("rand " + rand);

  }

  function initFirstContent() {
    const firstRow = $('.section-row').first();

    firstRow.fadeIn(transitionDuration)
      .addClass('current-section');

    firstRow.children('article').first()
      .addClass('current-article');

    refreshFooter();
    refreshArrowNavigation();
    refreshBreadcrumb();
  }


  // ------------------------- NAVIGATION
  function previousArticle(e) {
    if (e) {
      e.stopPropagation();
    }

    const currentArticle = $('.current-article'),
      previousArticle = currentArticle.prev('article');

    if (!isFooterShown()) {
      if ('undefined' !== typeof previousArticle.get(0)) {
        gotoArticle(previousArticle, .8);
      }
    } else {
      hideFooter();
      refreshArrowNavigation();
    }

  }

  function nextArticle(e) {
    if (e) {
      e.stopPropagation();
    }

    const currentArticle = $('.current-article'),
      nextArticle = currentArticle.next('article');

    if ('undefined' !== typeof nextArticle.get(0)) {
      gotoArticle(nextArticle, .8);
    } else {
      showFooter();
      refreshArrowNavigation();
    }
  }

  function firstArticle() {
    $('.current-article').removeClass('current-article');
    $('.current-section').css({
      left: 0
    });
    const nextArticle = $('.current-section > article').first();

    nextArticle.fadeIn(transitionDuration, () => {
      nextArticle.addClass('current-article');
    });
    refreshArrowNavigation();

  }

  function gotoArticle(article, delai) {
    const currentSection = $('.current-section'),
      currentArticle = $('.current-article');

    TweenMax.to(currentSection, delai, {
      left: 0 - (article.index() * vwSize.width) + 'px',
      ease: Power2.easeInOut,
      onComplete: myFonc
    })

    function myFonc() {

      currentArticle.removeClass('current-article');
      article.addClass('current-article');
      TweenMax.to($('.current-article > .article-content'), 0.5, {
        top: "50%"
      });
      if (article.attr("id") != "accueil") {
        $('.logo-top').addClass('logo-show');
      } else {
        $('.logo-top').removeClass('logo-show');
      }
      refreshArrowNavigation();
      refreshBreadcrumb();
    }
  }

  function previousSection() {
    const currentSection = $('.current-section');
    strobingPrev(currentSection.index() - 1);
  }

  function nextSection() {
    const currentSection = $('.current-section');
    strobing(currentSection.index() + 1);
  }

  function gotoSection(index, articleIndex = 0) {

    TweenMax.to($('#strobe-content'), 0.4, {
      opacity: 0,
      display: "none"
    });
    const currentSection = $('.current-section'),
      nextSection = $('.section-row').eq(index);

    if (index >= 0 && 'undefined' !== typeof nextSection.get(0)) {
      hideHeader();
      hideFooter();
      currentSection.fadeOut(0, () => {
        currentSection.removeClass('current-section');
        nextSection.fadeIn(0);
        nextSection.addClass('current-section');
        gotoArticle(nextSection.find('article:eq(' + articleIndex + ')'), 0);
        refreshFooter();
        refreshBreadcrumb();
      });
    }
  }


  // ------------------------- FOOTER
  function clickOutsideFooter(e) {
    const highestContainer = $(e.target).closest('#footer').get(0);

    if ('undefined' === typeof highestContainer) {
      hideFooter();
    }
  }
  var footShown = false;

  function showFooter() {
    const currentSection = $('.current-section'),
      childrenCount = currentSection.children('article').length - 1,
      footer = $('#footer');

    if (!footShown) {
      hideBreadCrumb();
      footer.addClass('shown');
      TweenMax.to(footer, .6, {
        right: '240px',
        ease: Power2.easeInOut
      })
      TweenMax.to(".article-content", 0.4, {
        opacity: 0,
        display: "none"
      });
      TweenMax.to('.relais', .6, {
        right: "256px",
        ease: Power2.easeInOut
      })
      TweenMax.to(currentSection, .6, {
        left: 0 - (childrenCount * vwSize.width) - 240 + 'px',
        ease: Power2.easeInOut
      })
      shiftBreadCrumb();
      $(document).on('click', clickOutsideFooter);
      footShown = true;
    }
  }

  function hideFooter() {
    if (footShown) {
      const currentSection = $('.current-section'),
        childrenCount = currentSection.children('article').length - 1,
        footer = $('#footer');

      footer.removeClass('shown');
      refreshBreadcrumb();
      TweenMax.to(footer, .6, {
        right: '0',
        ease: Power2.easeInOut
      })
      TweenMax.to(".article-content", 0.4, {
        opacity: 1,
        display: "block"
      });
      TweenMax.to('.relais', .6, {
        right: "16px",
        ease: Power2.easeInOut
      })
      TweenMax.to(currentSection, .6, {
        left: 0 - (childrenCount * vwSize.width) + 'px',
        ease: Power2.easeInOut
      })
      TweenMax.to('#arrow-next', .4, {
        opacity: 1,
        delay: .6,
        display: "block"
      })
      unshiftBreadCrumb();
      $(document).off('click', clickOutsideFooter);
      footShown = false;
    }
  }

  function isFooterShown() {
    return $('#footer').hasClass('shown')
  }

  function initFooter() {
    const nav = $('#nav-footer ul'),
      listItem = $('<li />').addClass('nav-item');
    let counter = 1;

    // create button from content
    $('.section-row:not(":first-child")').each((index, node) => {
      const content = $(node).children('article')
        .first()
        .find('h3').text();

      listItem.clone()
        .text(content)
        .appendTo(nav);

      counter++;
    });

    // init navigation on each button

    nav.find('li')
      .on('click', (e) => gotoSection($(e.target).index()))
  }

  function refreshFooter() {
    const currentSectionTitle = $('.current-section').next()
      .find('article:first-child h3').text(),
      footerTitle = $('#next-row');

    if (currentSectionTitle) {
      
      footerTitle.show();
      footerTitle.find('.title').text(currentSectionTitle);
      footerTitle.on('click', nextSection);
      if(currentSectionTitle == "Alexandre Gauthier" && document.querySelector('html').getAttribute('lang') == "jp"){
        footerTitle.find('.title').addClass('alex-jap-footer');
      }else{
        footerTitle.find('.title').removeClass('alex-jap-footer');
      }
    } else {
      if (document.querySelector('html').getAttribute('lang') == "en"){
        footerTitle.find('.title').text("Follow us on Twitter");
         footerTitle.find('.title').removeClass('alex-jap-footer');
      }else if (document.querySelector('html').getAttribute('lang') == "fr") {
        footerTitle.find('.title').text("Suivez-nous sur Twitter");
        footerTitle.find('.title').removeClass('alex-jap-footer');
      }else if (document.querySelector('html').getAttribute('lang') == "jp") {
        footerTitle.find('.title').html("Twitter<br><span class='twit'>でフォローする</span>");
        footerTitle.find('.title').removeClass('alex-jap-footer');
      }
      
      footerTitle.on('click', goTwit);
    }
  }

  function goTwit() {
    window.open("https://twitter.com/AGrenouillere");
  }

  // ------------------------- HEADER
  function toggleHeaderDisplay() {
    if (!isHeaderShown()) {
      showHeader();
    } else {
      hideHeader();
    }
  }

  function showHeader() {
    
    const header = $('#header');
    var article = $('.current-article')
    if (article.attr("id") == "accueil") {
        $('.logo-top').addClass('logo-show');
      } 
    header.addClass('shown');
    TweenMax.to(".nav-button span:nth-child(3)", 0.6, {
      ease: Power3.easeInOut,
      rotation: 45
    });
    TweenMax.to(".nav-button span:nth-child(2)", 0.6, {
      ease: Back.easeInOut,
      rotation: 135
    });
    TweenMax.to(".nav-button span:nth-child(1)", 0.4, {
      ease: Power3.easeInOut,
      left: 100,
      opacity: 0,
      display: 'none'
    });
    TweenMax.to(".nav-button span:nth-child(4)", 0.4, {
      ease: Power3.easeInOut,
      left: -100,
      opacity: 0,
      display: 'none'
    });
    //
    TweenMax.to(".nav-header", 0.4, {
      left: "90px",
      opacity: 1,
      ease: Power4.easeOut,
      display: "block",
      delay: .4
    });
    TweenMax.to(".nav-other", 0.4, {
      left: "160px",
      opacity: 1,
      ease: Power4.easeOut,
      display: "block",
      delay: .4
    });
    TweenMax.to(".overlay", 0.8, {
      opacity: .9,
      display: "block"
    });
    TweenMax.to(".nav-presse", 0.4, {
      opacity: 1,
      display: "block",
      delay: .8
    });
    TweenMax.to(".article-content", 0.4, {
      opacity: 0
    });
    TweenMax.to(".breadcrumb", 0.4, {
      opacity: 0
    });

  }

  function hideHeader() {
    const header = $('#header');
    var article = $('.current-article')
    if (article.attr("id") == "accueil") {
        $('.logo-top').removeClass('logo-show');
      } 
    header.removeClass('shown');
    TweenMax.to(".nav-button span:nth-child(3)", 0.4, {
      ease: Power3.easeInOut,
      rotation: 0
    });
    TweenMax.to(".nav-button span:nth-child(2)", 0.4, {
      ease: Power3.easeInOut,
      rotation: 0
    });
    TweenMax.to(".nav-button span:nth-child(1)", 0.4, {
      ease: Power3.easeInOut,
      left: 20,
      opacity: 1,
      display: 'block'
    });
    TweenMax.to(".nav-button span:nth-child(4)", 0.4, {
      ease: Power3.easeInOut,
      left: 20,
      opacity: 1,
      display: 'block'
    });

    TweenMax.to(".nav-header", 0.4, {
      left: "60px",
      opacity: 0,
      ease: Power3.easeIn,
      display: "none"
    });
    TweenMax.to(".nav-other", 0.4, {
      left: "200px",
      opacity: 0,
      ease: Power3.easeIn,
      display: "none"
    });
    TweenMax.to(".overlay", 0.8, {
      opacity: 0,
      display: "none",
      delay: .4
    });
    TweenMax.to(".nav-presse", 0.4, {
      opacity: 0,
      display: "none"
    });
    TweenMax.to(".article-content", 0.4, {
      opacity: 1,
      delay: .6
    });
    TweenMax.to(".breadcrumb", 0.4, {
      opacity: 1,
      delay: .6
    });

  }

  function isHeaderShown() {
    return $('#header').hasClass('shown')
  }

  function initHeader() {
    const nav = $('#nav-header ul'),
      listItem = $('<li />').addClass('nav-item'),
      ulItem = $('<ul>').addClass('nav-subnav');
    let counter = 1;

    // create button from content
    $('.section-row:not(":first-child")').each((index, node) => {
      const children = $(node).children('article'),
        content = children.first()
        .find('h3').text();

      const item = listItem.clone();

      item.append('<div class="text-nav">' + content + '</div>')
        .appendTo(nav);

      if (children.length > 1) {
        const sub = ulItem.clone();
        children.each((index, item) => {
          sub.append(
            listItem.clone()
            .text($(item).find('h2').text())
          )
        });
        item.append(sub);
      }

      counter++;
    });

    // init navigation on each button
    nav.find('> li')
      .each((index, item) => {
        $(item).on('click', (e) => strobing($(e.target).closest('li').index()));

        $(item).find('.nav-subnav li')
          .on('click', (e) => {
            e.stopPropagation();
            strobing(
              $(e.target).parent().closest('li').index(),
              $(e.target).index()
            )
          })
      });

    // init display toggle action
    $('#toggle-header').on('click', toggleHeaderDisplay)
    $('.overlay').on('click', hideHeader)

  }


  // ------------------------- KEYBOARD
  function initKeyboardListening() {
    document.body.addEventListener('keydown', (key) => {
      switch (key.keyCode) {
        case 38:
          previousSection();
          break;
        case 40:
          nextSection();
          break;
        case 37:
          previousArticle();
          break;
        case 39:
          nextArticle();
          break;
      }
    });
  }


  // ------------------------- ARROW NAVIGATION
  function initArrowNavigation() {
    $('#arrow-prev').on('click', previousArticle);
    $('#arrow-next').on('click', nextArticle);
  }
  var currentClassPrev = "";
  var currentClassNext = "";

  function refreshArrowNavigation() {
    const prevArrow = $('#arrow-prev'),
      nextArrow = $('#arrow-next'),
      previousArticle = $('.current-article').prev('article'),
      nextArticle = $('.current-article').next('article');

    var prevClass = "";
    if ('undefined' !== typeof previousArticle.get(0)) {
      prevArrow.find('.preview').text(previousArticle.find('h2').text());
      if (previousArticle.find('h2').text() == "Séjours et petits déjeuners") {
        prevArrow.find('.preview').text("Séjours");
      }
      prevArrow.find('.preview-img').removeClass(currentClassPrev);
      prevArrow.find('.preview-img').addClass(previousArticle.attr("id"));
      currentClassPrev = previousArticle.attr("id");
      TweenMax.to(prevArrow, .4, {
        opacity: 1,
        delay: .6,
        display: "block"
      })
    } else {
      TweenMax.to(prevArrow, .4, {
        opacity: 0,
        display: "none"
      })
    }


    if (!footShown) {
      if (document.querySelector('html').getAttribute('lang') == "en"){
        nextArrow.find('.preview').text(nextArticle.find('h2').text() || 'Next');
      }else if(document.querySelector('html').getAttribute('lang') == "fr"){
        nextArrow.find('.preview').text(nextArticle.find('h2').text() || 'Suite');
      }else if(document.querySelector('html').getAttribute('lang') == "jp"){
        nextArrow.find('.preview').text(nextArticle.find('h2').text() || '次のステップ');
      }
      if (nextArticle.find('h2').text() == "Séjours et petits déjeuners") {
         if (document.querySelector('html').getAttribute('lang') == "en"){
            nextArrow.find('.preview').text("Stays");
         }else{
           nextArrow.find('.preview').text("Séjours");
         }
      }


      nextArrow.find('.preview-img').removeClass(currentClassNext);
      nextArrow.find('.preview-img').addClass(nextArticle.attr("id"));
      currentClassNext = nextArticle.attr("id");
      if (nextArrow.find('.preview').text() == "Suite" || nextArrow.find('.preview').text() == "Next" || nextArrow.find('.preview').text() == "次のステップ") {

        nextArrow.find('.preview-img').addClass("end");
        currentClassNext = "end";
      }
      TweenMax.to(nextArrow, .4, {
        opacity: 1,
        delay: .6,
        display: "block"
      })
    } else {
      TweenMax.to(nextArrow, .4, {
        opacity: 0,
        display: "none"
      })
    }
  }


  // ------------------------- BREADCRUMB
  function refreshBreadcrumb() {
    const currentSection = $('.current-section'),
      titleList = $('.current-section h2'),
      currentArticlePosition = $('.current-article').index(),
      progress = $('#bc-progress'),
      grip = $('#bc-grip'),
      bcContainer = $('#bc-content')
      .empty(),
      item = $('<li />')
      .addClass('col-' + Math.round(1 / titleList.length * 100)),
      link = $('<a />')
      .attr('href', 'javascript:;');

    // refresh breadcrumb content
    titleList.each((index, title) => {
      item.clone()
        .append(
          $(link).clone()
          .text($(title).text())
          .on('click', (e) => {
            gotoArticle(
              currentSection.find('article')
              .eq($(e.target).closest('li')
                .index())
            );
          })
        )
        .appendTo(bcContainer)
    });

    // refresh progress position
    const progressPosition = (currentArticlePosition / titleList.length) + (1 / (2 * titleList.length));
    if (1 < titleList.length) {
      grip.css({
        'left': (100 * progressPosition) + '%',
        'opacity': Math.ceil(progressPosition)
      });
      progress.css('stroke-dashoffset', (100 - (100 * progressPosition)));
      titleList.find('.active').removeClass('.active');
      bcContainer.find('li')
        .eq(currentArticlePosition)
        .addClass('active');

      showBreadCrumb();
    } else {
      grip.css({
        'left': '0%',
        'opacity': 0
      });
      progress.css('stroke-dashoffset', 100);
      titleList.find('.active').removeClass('.active');
      hideBreadCrumb();
    }
  }

  function showBreadCrumb() {
    $('#breadcrumb').addClass('shown');
  }

  function hideBreadCrumb() {
    $('#breadcrumb').removeClass('shown');
  }

  function shiftBreadCrumb() {

    TweenMax.to('#breadcrumb', .6, {
      right: '240px',
      ease: Power2.easeInOut
    })
  }

  function unshiftBreadCrumb() {

    TweenMax.to('#breadcrumb', .6, {
      right: '6px',
      ease: Power2.easeInOut
    })
  }
  // ------------------------- SLIDESHOW


  // ------------------------- POPUP
  function openPopup(target) {

    $('#popup-content').empty()
      .append($('#' + target).clone()
        .removeAttr('id')
        .removeClass('popup-content'));
    if ($('#' + target).hasClass('slide')) {
      $('#popup-container').addClass('popupSlide');
      $('#popup').addClass(target);
      initSlideshow('.' + target);
    } else {
      $('#popup-container').removeClass('popupSlide');
    }
    if ($('#' + target).hasClass('video')) {
      $('#popup-container').addClass('popupVideo');
    } else {
      $('#popup-container').removeClass('popupVideo');
    }
    TweenMax.to('#popup-container', .4, {
      opacity: 1,
      display: "block"
    })
    TweenMax.to('#popup', .4, {
      opacity: 1,
      display: "block"
    })
  }
  window.openPopup = openPopup;

  function closePopup() {
    TweenMax.to('#popup-container', .4, {
      opacity: 0,
      display: "none"
    })
    TweenMax.to('#popup', .4, {
      opacity: 0,
      display: "none"
    })
  }
  window.closePopup = closePopup;

  function initSlideshow(target) {

    var cible = $(target);
    var self = $('#slideshow', cible);
    var slides = self.find('#diapos');

    self.currentPosition = 1;
    self.nbrSlide = slides.children().length;

    var btnNext = $('.next', cible);
    var btnPrev = $('.prev', cible);


    btnNext.on('click', function () {
      var currentDiapoNext = self.find('.diapo' + self.currentPosition);
      TweenMax.to(currentDiapoNext, 0.6, {
        opacity: 0,
        display: "none"
      });
      if (self.currentPosition < self.nbrSlide) {
        self.currentPosition++;
      } else {
        self.currentPosition = 1;
      }
      currentDiapoNext = self.find('.diapo' + self.currentPosition);
      TweenMax.to(currentDiapoNext, 0.6, {
        opacity: 1,
        display: "block"
      });
    });
    btnPrev.on('click', function () {
      var currentDiapoPrev = self.find('.diapo' + self.currentPosition);
      TweenMax.to(currentDiapoPrev, 0.6, {
        opacity: 0,
        display: "none"
      });
      if (self.currentPosition > 1) {
        self.currentPosition--;
      } else {
        self.currentPosition = self.nbrSlide;
      }
      currentDiapoPrev = self.find('.diapo' + self.currentPosition);
      TweenMax.to(currentDiapoPrev, 0.6, {
        opacity: 1,
        display: "block"
      });
    });
  }

  function initSlideshowLieux() {

    var base = $('.en-image');

    var slideslieux = base.find('#diapos-lieux');
    $('.btn1').addClass('btn-activ');
    base.currentPosition = 1;
    base.nbrSlide = slideslieux.children().length;

    console.log("nbr : " + slideslieux.children().length);

    var btnNextLieux = $('.nav-next', base);
    var btnPrevLieux = $('.nav-prev', base);


    btnNextLieux.on('click', function () {

      var currentDiapoNextLieux = slideslieux.find('.diapo-l' + base.currentPosition);
      var currentSelectLieux = base.find('.btn' + base.currentPosition);
      currentSelectLieux.removeClass('btn-activ');

      TweenMax.to(currentDiapoNextLieux, 0.6, {
        opacity: 0,
        display: "none"
      });
      if (base.currentPosition < base.nbrSlide) {
        base.currentPosition++;
      } else {
        base.currentPosition = 1;
      }
      currentDiapoNextLieux = slideslieux.find('.diapo-l' + base.currentPosition);
      TweenMax.to(currentDiapoNextLieux, 0.6, {
        opacity: 1,
        display: "block"
      });
      currentSelectLieux = base.find('.btn' + base.currentPosition);
      currentSelectLieux.addClass('btn-activ');
    });

    btnPrevLieux.on('click', function () {
      var currentDiapoPrevLieux = slideslieux.find('.diapo-l' + base.currentPosition);
      TweenMax.to(currentDiapoPrevLieux, 0.6, {
        opacity: 0,
        display: "none"
      });
      var currentSelectLieuxPrev = base.find('.btn' + base.currentPosition);
      currentSelectLieuxPrev.removeClass('btn-activ');

      if (base.currentPosition > 1) {
        base.currentPosition--;
      } else {
        base.currentPosition = base.nbrSlide;
      }
      currentDiapoPrevLieux = slideslieux.find('.diapo-l' + base.currentPosition);
      TweenMax.to(currentDiapoPrevLieux, 0.6, {
        opacity: 1,
        display: "block"
      });
      currentSelectLieuxPrev = base.find('.btn' + base.currentPosition);
      currentSelectLieuxPrev.addClass('btn-activ');
    });
  }

  function strobingPrev(next, artic) {
    hideFooter();
    hideHeader();
    TweenMax.delayedCall(.7, gotoSection, [next, artic]);
    TweenMax.to($('#strobe-content'), 0.5, {
      opacity: 1,
      display: "block"
    });
    if ($('.current-article').attr("id") != "les-lieux") {
      TweenMax.to($('.current-article > .article-content'), 0.5, {
        top: "52%"
      });
    }
  }

  function strobing(next, artic) {
    hideHeader();
    hideFooter();
    TweenMax.delayedCall(.7, gotoSection, [next, artic]);
    TweenMax.to($('#strobe-content'), 0.5, {
      opacity: 1,
      display: "block"
    });
    if ($('.current-article').attr("id") != "les-lieux") {
      TweenMax.to($('.current-article > .article-content'), 0.5, {
        top: "48%"
      });
    }

  }

  $(document).ready(() => {
    goIn();
    initRow();
    initArticle();
    initPreload();
    if (!$('body').hasClass('home')) {
      initKeyboardListening();
      initArrowNavigation();
      initHeader();
      initFooter();
      initSlideshowLieux();
    }

    initFirstContent();
  });
})();
